// website/wedding-photo-upload/src/HomeScreen.js
import React from 'react';

const HomeScreen = ({ onChooseUpload, onChooseTablePlan }) => {
  return (
    <div className="home-screen">
      <h1>Welcome to our wedding!</h1>
      <button className="option-button rounded-xl" onClick={onChooseUpload}>
        Share your Photos
      </button>
      <button className="option-button rounded-xl" onClick={onChooseTablePlan}>
        Check your seat
      </button>
    </div>
  );
};

export default HomeScreen;