// website/wedding-photo-upload/src/PhotoUpload.js
import React, { useState } from 'react';
import axios from 'axios';

const PhotoUpload = () => {
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    setFileNames(Array.from(selectedFiles).map(file => file.name));
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('No files selected. Please select files to upload.');
      setSuccess(null);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('foldername', folderName);

    try {
      const response = await axios.post(`${window.location.origin}/api/uploads/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setSuccess("Files uploaded successfully!\n Thank you for sharing your memories with us.");
      setError(null);
      setFiles([]);
      setFileNames([]);
    } catch (error) {
      console.error('Error uploading files:', error.message); // Print error message
      console.error('Error details:', JSON.stringify(error)); // Print entire error object as string
      setError('Failed to upload files. Please try again later.');
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
  };

  return (
    <div className="flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-md">
      <input
        type="file"
        accept="image/*,video/*"
        // capture="environment"
        // capture="user"
        multiple
        onChange={handleFileChange}
        className="mb-4 p-2 border border-gray-300 rounded-lg"
      />
      <input
        type="text"
        placeholder="Your name (optional)"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded-lg"
      />
      <div className="file-list-container mb-4 p-2 border border-gray-300 rounded-lg">
        <ul className="file-list">
          {fileNames.map((fileName, index) => (
            <li key={index} className="text-gray-700">{fileName}</li>
          ))}
        </ul>
      </div>
      <button
        onClick={handleUpload}
        className="upload-photo rounded-xl"
        disabled={loading}
      >
        {loading ? 'Uploading...' : 'Upload Photos'}
      </button>
      {error && (
        <div className="mt-4 p-2 text-red-500">
          {error}
          <button onClick={handleClose} className="ml-4 underline">Close</button>
        </div>
      )}
      {success && (
        <div className="mt-4 p-2 text-green-500" style={{ whiteSpace: 'pre-line' }}>
          {success}
          <button onClick={handleClose} className="ml-4 underline">Close</button>
        </div>
      )}
    </div>
  );
};

export default PhotoUpload;