// website/wedding-photo-upload/src/TablePlanPopup.js
import React, { useState } from 'react';
import './TablePlanPopup.css';

const TablePlanPopup = ({ images, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <button className="nav-button left" onClick={handlePrev}>‹</button>
        <img src={images[currentImageIndex]} alt="Table Plan" className="popup-image" />
        <button className="nav-button right" onClick={handleNext}>›</button>
      </div>
    </div>
  );
};

export default TablePlanPopup;