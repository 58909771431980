// website/wedding-photo-upload/src/App.js
import React, { useState, useEffect } from 'react';
import PhotoUpload from './PhotoUpload';
import HomeScreen from './HomeScreen';
import TablePlanPopup from './TablePlanPopup';
import './index.css';

const App = () => {
  const [view, setView] = useState('home');
  const [showPopup, setShowPopup] = useState(false);

  const images = ['/table_plan/4.png', '/table_plan/5.png'];

  const handleChooseUpload = () => {
    setView('upload');
    window.history.pushState(null, '', '/upload');
  };

  const handleChooseTablePlan = () => {
    setShowPopup(true);
  };

  const handleBack = () => {
    setView('home');
    window.history.pushState(null, '', '/');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const handlePopState = () => {
      setView('home');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className="app">
      <div className="background"></div>
      <h2 className="groom-and-bride p-6">Mino <br />&<br /> Andrianina</h2>
      <div className="container mx-auto">
        {view !== 'home' && (
          <button className="back-button" onClick={handleBack}>
            &larr;
          </button>
        )}
        {view === 'home' && (
          <HomeScreen
            onChooseUpload={handleChooseUpload}
            onChooseTablePlan={handleChooseTablePlan}
          />
        )}
        {view === 'upload' && (
          <>
            <h1 className="text-3xl font-bold mb-4">Photos & videos Upload</h1>
            <PhotoUpload />
          </>
        )}
        {showPopup && (
          <TablePlanPopup images={images} onClose={handleClosePopup} />
        )}
      </div>
      <footer className="footer p-4 text-center">
        <p>By <a href="https://www.linkedin.com/in/mino-randrianarivo/" target="blank"> the groom </a> &copy; 2024</p>
      </footer>
    </div>
  );
};

export default App;